import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        primary: "#B2D234",
        secondary: "#002776",
        accent: "#79BC36",
        error: "#FF0048",
        info: "#B2D234",
        success: "#79BC36",
        warning: "#B2D234",
        leftPanel: "#F5F5F5",
        rightPanel: "#ffffff",
        textColor: "#000000",
        textColorLeft: "#000000",
        statementText: "#002776",
        spec: "#003895",
      },
      dark: {
        primary: "#B2D234",
        secondary: "#002776",
        accent: "#79BC36",
        error: "#FF0048",
        info: "#B2D234",
        success: "#79BC36",
        warning: "#B2D234",
        leftPanel: "#B2D234",
        rightPanel: "#000000",
        textColor: "#ffffff",
        textColorLeft: "#ffffff",
        statementText: "#F5F5F5",
        spec: "#FFEA00",
      },
    },
  },
});
